@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, h1, h2, h3, h4, h5, .ui {
  font-family: Lato, Arial, sans-serif !important;
}

body, h1, h2, h3, h4, h5, .ui {
  font-weight: 400;
}

.App {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #1E2329;
  background-color: #FFFFFF;
}

.notice {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  width: 100%;
  background-color: #e5f6ff;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.notice-icon {
  box-sizing: border-box;
  margin: 2px 4px 2px 0px;
  min-width: 0px;
  color: rgb(14, 203, 129);
  font-size: 20px;
  fill: rgb(14, 203, 129);
  width: 1em;
  height: 1em;
}

main {
  box-sizing: border-box;
  margin: 0px 0px 0px;
  min-width: 0px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex: 1 1;
  flex-direction: column;
}

.description {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #474D57;
  margin-bottom: 32px;
}

footer {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #474D57;
  text-align: center;
  padding: 12px;
}

.btn-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #4183c4;
  cursor: pointer;
  font-family: Lato, Arial, sans-serif !important;
}

